/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 character.gltf 
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'  // Import the useFrame hook
import * as THREE from 'three';

export function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/character.gltf')
  const [localpallete, setLocalpallete] = useState(JSON.parse(localStorage.getItem('Pallete')))
  const materiall = new THREE.MeshBasicMaterial({ color: localpallete[0].color });
  // useFrame(() => {
  //   group.current.rotation.y = 1;
  // })
  useEffect(() => {
    const alertMessage = () => {
      //alert('localStorage changed!');
      setLocalpallete(JSON.parse(localStorage.getItem('Pallete')))
      console.log("localStorage changed!'")
    }

    //window.localStorage.setItem("item", 'val 1');
    window.addEventListener('Pallete', alertMessage);

    //Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("Pallete", alertMessage);
    }
  }, []);

  return (
    <group >
      <group {...props} dispose={null}  >
        <directionalLight intensity={1} decay={2} position={[27.127, 6.914, -0.368]} />
        <directionalLight intensity={2} decay={2} position={[-19.859, 10, 0.594]} />
        <pointLight intensity={1} decay={2} position={[1.803, 10, 0.23]} />
        <PerspectiveCamera makeDefault={true} position={[6.96, 9.714, 5.127]} rotation={[-0.808, 0.821, 0.654]} />
        <group {...props}>
        <mesh geometry={nodes.pants002.geometry} material={materials['Material.012']} position={[0, 7.801, 0.015]} />
        <mesh geometry={nodes.Cube004.geometry} material={materials.Material} position={[0, 7.801, 0.008]}>
          <mesh geometry={nodes.Cube005.geometry} material={materials['Material.009']} />
          <mesh geometry={nodes.Hoodie001.geometry} material={materials['Material.008']} />
          <mesh geometry={nodes.Jordans010.geometry} material={materials.Material} position={[-0.06, -7.448, -0.913]} scale={0.339} />
          <mesh geometry={nodes.Jordans011.geometry} material={materiall} position={[-0.06, -7.448, -0.913]} scale={0.339} />
          <mesh geometry={nodes.Jordans012.geometry} material={materials['RED-JORDANS']} position={[-0.06, -7.448, -0.913]} scale={0.339} />
          <mesh geometry={nodes.Jordans013.geometry} material={materials.Material} position={[-0.045, -7.448, 0.895]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.339, 0.339, 0.339]} />
          <mesh geometry={nodes.Jordans014.geometry} material={materiall} position={[-0.045, -7.448, 0.895]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.339, 0.339, 0.339]} />
          <mesh geometry={nodes.Jordans015.geometry} material={materials['RED-JORDANS']} position={[-0.045, -7.448, 0.895]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.339, 0.339, 0.339]} />
          <mesh geometry={nodes.pants001.geometry} material={materials['Material.004']} />
          <mesh geometry={nodes.Text001.geometry} material={materials.NIKE} position={[0.699, -1.715, -0.276]} rotation={[1.503, 0, -1.71]} scale={0.073} />
        </mesh>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/character.gltf')
