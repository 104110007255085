/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 figurka3.gltf 
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'
import * as THREE from 'three'
export function Model3_figurka(props) {
  const { nodes, materials } = useGLTF('/figurka3.gltf')
  const [localpallete, setLocalpallete] = useState(JSON.parse(localStorage.getItem('Pallete')))
  const materiall = new THREE.MeshBasicMaterial({ color: localpallete[0].color });
  useEffect(() => {
    const alertMessage = () => {
      //alert('localStorage changed!');
      setLocalpallete(JSON.parse(localStorage.getItem('Pallete')))
      console.log("localStorage changed!'")
    }

    //window.localStorage.setItem("item", 'val 1');
    window.addEventListener('Pallete', alertMessage);

    //Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("Pallete", alertMessage);
    }
  }, []);

  return (
    <group {...props} dispose={null}>
      <directionalLight  intensity={1.5} position={[1.276, -1.721, -13.567]} />
      <PerspectiveCamera makeDefault={true} far={1000} near={0.1} fov={34.708} position={[2.529, 3.593, -7.313]} rotation={[-2.971, 0.338, 3.085]} />
      <mesh geometry={nodes.Cube.geometry} material={materials['Material.001']} position={[0, 3.257, 0]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.002']} position={[0.352, 3.442, -0.739]} rotation={[1.865, 0, 0.217]} scale={0.197} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.002']} position={[-0.33, 3.442, -0.739]} rotation={[-1.277, 0, 0.217]} scale={-0.197} />
      <mesh geometry={nodes.Cube001.geometry} material={materiall} position={[0, 3.257, 0]} />
      <mesh geometry={nodes.Cube002.geometry} material={materials['Material.004']} position={[0.879, 3.343, -0.049]} scale={0.297} />
      <mesh geometry={nodes.Cube003.geometry} material={materials['Material.004']} position={[-0.834, 3.343, -0.049]} scale={0.297} />
      <mesh geometry={nodes.Cube004.geometry} material={materials['Material.004']} position={[1.112, 3.818, -0.046]} scale={0.172} />
      <mesh geometry={nodes.Cube005.geometry} material={materials['Material.004']} position={[-1.068, 3.818, -0.046]} rotation={[-Math.PI, 0, 0]} scale={-0.172} />
      <mesh geometry={nodes.Jordans003.geometry} material={materials['Material.001']} position={[-0.413, 0.242, 0.021]} rotation={[0, Math.PI / 2, 0]} scale={0.158} />
      <mesh geometry={nodes.Jordans001.geometry} material={materials['Material.003']} position={[-0.413, 0.242, 0.021]} rotation={[0, Math.PI / 2, 0]} scale={0.158} />
      <mesh geometry={nodes.Jordans.geometry} material={materials['Material.001']} position={[-0.413, 0.242, 0.021]} rotation={[0, Math.PI / 2, 0]} scale={0.158} />
      <mesh geometry={nodes.Jordans002.geometry} material={materials['Material.001']} position={[0.451, 0.242, 0.021]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={-0.158} />
      <mesh geometry={nodes.Jordans004.geometry} material={materials['Material.003']} position={[0.451, 0.242, 0.021]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={-0.158} />
      <mesh geometry={nodes.Jordans005.geometry} material={materials['Material.001']} position={[0.451, 0.242, 0.021]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={-0.158} />
      <mesh geometry={nodes.Cube008.geometry} material={nodes.Cube008.material} position={[-0.959, 1.831, -1.058]} rotation={[0.061, 0, 0]} scale={0.698} />
      <mesh geometry={nodes.Cube009.geometry} material={nodes.Cube009.material} position={[-0.959, 2.325, -1.447]} rotation={[1.631, 0, 0]} scale={0.698} />
      <mesh geometry={nodes.Path_20.geometry} material={materials['Material.004']} position={[-1.058, 2.218, -1.496]} rotation={[-1.497, 0, 0]} scale={[-14.047, -130.834, -14.047]} />
      <mesh geometry={nodes.Cube006.geometry} material={materials['Material.005']} position={[0.021, 2.901, 0.008]} rotation={[0, Math.PI / 2, 0]} scale={0.476} />
      <mesh geometry={nodes.Cube007.geometry} material={materials['Material.004']} position={[0.021, 2.901, 0.008]} rotation={[0, Math.PI / 2, 0]} scale={0.476} />
    </group>
  )
}

useGLTF.preload('/figurka3.gltf')
